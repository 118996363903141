import { CssBaseline } from "@material-ui/core"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Footer } from "./footer"
import { Navbar } from "../navbar/navbar"
import { Menu } from "./menu"
import Logo from "../../images/logo.svg"
import SEO from "./seo"

const Layout = ({ children }) => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  const menuLinks = [
    { name: "About", link: "/about" },
    { name: "Works", link: "/works" },
    { name: "News", link: "/news" },
    { name: "Careers", link: "/careers" },
    { name: "Contact", link: "/contact" },
  ]

  return (
    <main>
      <CssBaseline />
      <SEO title="DIGIC" />
      <Navbar setMenuOpen={setMenuOpen} logo={Logo} to="/" />
      {children}
      <Menu
        isOpen={isMenuOpen}
        close={() => setMenuOpen(false)}
        menuLinks={menuLinks}
      />
      <Footer />
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
