import { Box, Button, Typography, makeStyles } from "@material-ui/core"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { DigicLink } from "../digic-link"

const useStyles = makeStyles(theme => ({
  descriptionWrapper: {
    display: "flex",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  employeeWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "50%",
    padding: "48px 16px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  imagesWrapper: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  contentWrapper: {
    padding: "16px",
    marginRight: "40px",
    alignSelf: "flex-end",
    [theme.breakpoints.down("md")]: {
      alignSelf: "center",
      marginRight: "0px",
    },
  },
}))

export const CsrEmployee = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        csrPage {
          EmployeeButton
          EmployeeDescriptions
          EmployeeTitle
          EmployeeGallery {
            images {
              url
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const classes = useStyles()

  return (
    <Box className={classes.descriptionWrapper}>
      <Box className={classes.employeeWrapper}>
        <Box className={classes.contentWrapper}>
          <Typography
            variant="h2"
            style={{
              width: "100%",
              marginBottom: "24px",
              marginTop: "40px",
            }}
          >
            {data.strapi.csrPage.EmployeeTitle}
          </Typography>
          <Typography
            variant="body1"
            style={{
              width: "100%",
              marginBottom: "20px",
              maxWidth: "570px",
            }}
          >
            {data.strapi.csrPage.EmployeeDescriptions}
          </Typography>
          <DigicLink to="/join-us">
            <Button
              variant="contained"
              style={{
                marginTop: "20px",
                marginBottom: "30px",
              }}
            >
              {data.strapi.csrPage.EmployeeButton}
            </Button>
          </DigicLink>
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap" className={classes.imagesWrapper}>
        {data.strapi.csrPage.EmployeeGallery.map(({ images }) => (
          <Img
            key={images}
            style={{ width: "33%" }}
            fluid={images.imageFile.childImageSharp.fluid}
          />
        ))}
      </Box>
    </Box>
  )
}
