import { Box, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Img from "gatsby-image"
import React from "react"

const useStyles = makeStyles(theme => ({
  title: {
    width: "100%",
    textAlign: "center",
    margin: "48px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "24px 0",
    },
  },
  container: {
    position: "relative",
    paddingTop: "120px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "96px",
    },
  },
}))

export const CsrHero = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    {
      strapi {
        csrPage {
          Descriptions
          HeroBg {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          LittleTitle
          Title
        }
      }
    }
  `)

  return (
    <Box className={classes.container}>
      <Box>
        <Img
          style={{
            minHeight: "550px",
            maxHeight: "800px",
            objectFit: "cover",
            filter: "brightness(0.5)",
          }}
          fluid={data.strapi.csrPage.HeroBg.imageFile.childImageSharp.fluid}
        />
        <Box
          position="absolute"
          my={5}
          px={2}
          top={0}
          left={0}
          height="100%"
          width="100%"
          overflow="hidden"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            maxWidth="820px"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h1"
              align="center"
              style={{ text: "uppercase" }}
            >
              {data.strapi.csrPage.Title}
            </Typography>
            <Typography variant="caption" style={{ marginTop: "48px" }}>
              {data.strapi.csrPage.LittleTitle}
            </Typography>
            <Typography
              style={{
                textAlign: "center",
                margin: "20px 0",
                maxWidth: "790px",
              }}
              variant="body1"
            >
              {data.strapi.csrPage.Descriptions}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
