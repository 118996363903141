import { Box } from "@material-ui/core"
import React from "react"
import Layout from "../components/layout/layout-home"
import { CsrHero } from "../components/csr/csr-hero"
import { CsrDonations } from "../components/csr/csr-donations"
import { CsrActivities } from "../components/csr/csr-activities"
import { CsrCharity } from "../components/csr/csr-charity"
import { CsrNext } from "../components/csr/csr-next"
import { CsrEmployee } from "../components/csr/csr-employee"
import Fade from "react-reveal/Fade"

const CsrPage = () => {
  return (
    <Layout>
      <Box>
        <Fade>
          <CsrHero />
          <CsrDonations />
          <CsrActivities />
          <CsrCharity />
          <CsrEmployee />
          <CsrNext />
        </Fade>
      </Box>
    </Layout>
  )
}

export default CsrPage
