import { Box, Button, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { DigicLink } from "../digic-link"

const useStyles = makeStyles(theme => ({
  title: {
    width: "100%",
    textAlign: "center",
    padding: "48px 16px",
    [theme.breakpoints.down("sm")]: {
      margin: "24px 0",
      padding: "48px 16px",
    },
  },
  container: {
    position: "relative",
  },
}))

export const CsrNext = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    {
      strapi {
        csrPage {
          NextButton
          NextDescription
          NextTitle
          NextTitleColored
        }
      }
    }
  `)

  return (
    <Box className={classes.container} style={{ backgroundColor: "#1E2021" }}>
      <Box
        px={2}
        top={0}
        left={0}
        height="100%"
        width="100%"
        overflow="hidden"
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{
          backgroundColor: "#1E2021",
          padding: "48px 24px",
        }}
      >
        <Box
          maxWidth="900px"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h2"
            align="center"
            style={{
              text: "uppercase",
              marginTop: "50px",
              display: "inline",
            }}
            color="primary"
          >
            <span style={{ color: "#FFFFFF" }}>
              {" "}
              {data.strapi.csrPage.NextTitle}
            </span>{" "}
            {data.strapi.csrPage.NextTitleColored}
          </Typography>

          <Typography
            style={{
              textAlign: "center",
              margin: "20px 0",
              maxWidth: "750px",
            }}
            variant="body1"
          >
            {data.strapi.csrPage.NextDescription}
          </Typography>
          <DigicLink
            to="/contact"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textDecoration: "none",
            }}
          >
            <Button
              variant="contained"
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              {data.strapi.csrPage.NextButton}
            </Button>
          </DigicLink>
        </Box>
      </Box>
    </Box>
  )
}
