import { Box, Typography, Button } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import Img from "gatsby-image"
import "react-multi-carousel/lib/styles.css"
import "./carusel-arrows.css"
import { DigicLink } from "../digic-link"
import Carousel from "react-multi-carousel"

const useStyles = makeStyles(theme => ({
  title: {
    width: "100%",
    textAlign: "center",
    margin: "48px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "24px 0",
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    padding: "48px 16px",
    minHeight: "40vh",
    "& .react-multi-carousel-list": {
      height: "300px",
    },
    "& .react-multi-carousel-track": {
      height: "300px",
    },
  },
  content: {
    maxWidth: "1200px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  textContainer: {
    paddingRight: "64px",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#E9E9E9",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0px",
    },
  },
}))

const caroulBreakpoints = {
  desktop: {
    breakpoint: {
      max: 6000,
      min: 0,
    },
    items: 1,
  },
}

export const CsrCharity = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    {
      strapi {
        csrPage {
          CharityDescription
          CharityTitle
          CharityBeneficiaries {
            Description
            Name
            Logo {
              url
              imageFile {
                childImageSharp {
                  fixed(width: 150) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
        charityOfTheMonth {
          charities {
            year
            logos {
              url
              imageFile {
                childImageSharp {
                  fixed(width: 150) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Box className={classes.container} style={{ backgroundColor: "#E9E9E9" }}>
      <Box className={classes.content}>
        <Box className={classes.textContainer}>
          <Box
            maxWidth="900px"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h1"
              align="start"
              style={{
                width: "100%",
                text: "uppercase",
                marginTop: "50px",
              }}
              color="white"
            >
              <span
                style={{
                  backgroundColor: "#f26522",
                  padding: "0 8px",
                }}
              >
                {data.strapi.csrPage.CharityTitle}
              </span>
            </Typography>
            <Typography
              style={{
                textAlign: "start",
                margin: "20px 0",
                maxWidth: "900px",
                color: "#343333",
              }}
              variant="body1"
            >
              {data.strapi.csrPage.CharityDescription}
            </Typography>
            <DigicLink to="/charities">
              <Button
                variant="outlined"
                style={{
                  marginTop: "20px",
                  marginBottom: "30px",
                }}
              >
                ALL CHARITIES
              </Button>
            </DigicLink>
          </Box>
        </Box>
        <Box width="300px" height="300px" margin="auto">
          <Carousel
            additionalTransfrom={0}
            focusOnSelect={false}
            responsive={caroulBreakpoints}
            slidesToSlide={1}
            infinite
            arrows={false}
            autoPlay
          >
            {data.strapi.charityOfTheMonth.charities[0].logos.map(
              (logo, index) => (
                <div
                  key={`charity-carousel-${index}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Img
                    style={{}}
                    fixed={logo.imageFile.childImageSharp.fixed}
                  />
                </div>
              )
            )}
          </Carousel>
        </Box>
      </Box>
    </Box>
  )
}
