import { Box, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import Carousel from "react-multi-carousel"
import Img from "gatsby-image"
import "react-multi-carousel/lib/styles.css"
import "./carusel-arrows.css"

const caroulBreakpoints = {
  mobile: {
    breakpoint: {
      max: 767,
      min: 0,
    },
    items: 1,
  },
  tablet: {
    breakpoint: {
      max: 1023,
      min: 768,
    },
    items: 2,
  },
  tabletWide: {
    breakpoint: {
      max: 1279,
      min: 1024,
    },
    items: 3,
  },
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1280,
    },
    items: 4,
  },
}

const useStyles = makeStyles(theme => ({
  title: {
    width: "100%",
    textAlign: "center",
    margin: "48px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "24px 0",
    },
  },
  container: {
    position: "relative",
    padding: "48px 16px",
  },
  exampleText: {
    color: "#aaa",
    marginTop: "32px",
  },
}))

export const CsrActivities = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    {
      strapi {
        csrPage {
          ActivitiesDescription
          ActivitiesTitle
          ActivitiesBeneficiaries {
            Description
            Name
            Logo {
              url
              imageFile {
                childImageSharp {
                  fixed(width: 150) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Box className={classes.container}>
      <Box
        px={2}
        top={0}
        left={0}
        height="100%"
        width="100%"
        overflow="hidden"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          maxWidth="900px"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h2"
            align="center"
            style={{
              text: "uppercase",
              marginTop: "50px",
            }}
            color="primary"
          >
            {data.strapi.csrPage.ActivitiesTitle}
          </Typography>
          <Typography
            style={{
              textAlign: "center",
              margin: "20px 0",
              maxWidth: "900px",
            }}
            variant="body1"
          >
            {data.strapi.csrPage.ActivitiesDescription}
          </Typography>
        </Box>
        <Typography variant="caption" className={classes.exampleText}>
          Here are some examples
        </Typography>
      </Box>
      <Box
        maxWidth="1200px"
        py={5}
        style={{ marginLeft: "auto", marginRight: "auto" }}
      >
        <Carousel
          additionalTransfrom={0}
          arrows
          containerClass="book-swiper"
          dotListClass=""
          draggable
          focusOnSelect={false}
          keyBoardControl
          responsive={caroulBreakpoints}
          slidesToSlide={1}
          swipeable
        >
          {data.strapi.csrPage.ActivitiesBeneficiaries.map(
            ({ Logo, Name, Description }) => (
              <Box
                key={Name}
                style={{
                  maxWidth: "230px",
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Box
                  style={{
                    marginBottom: "30px",
                    margin: "auto",
                    height: "150px",
                    display: "flex",
                    verticalAlign: "center",
                  }}
                >
                  <Img
                    style={{
                      margin: "auto",
                    }}
                    fixed={Logo.imageFile.childImageSharp.fixed}
                  />
                </Box>
                <Box
                  style={{
                    minHeight: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="caption">{Name}</Typography>
                </Box>
                <Typography
                  style={{
                    margin: "20px 0",
                  }}
                  variant="body2"
                >
                  {Description}
                </Typography>
              </Box>
            )
          )}
        </Carousel>
      </Box>
    </Box>
  )
}
